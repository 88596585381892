const SaveForLater = {

    init: function () {
        this.bindUIActions();
    },

    bindUIActions: function () {
        $('.save-for-later .save-panel').click(function () {
            const panelBlock = $(this).closest('.panel-block');
            const name = panelBlock.attr('data-panel-name');
            const url = panelBlock.find('a[rel~=sponsored]')[0].href;

            const clipboard = name + "\n" + url;
            const mailto = "mailto:?subject=" + encodeURIComponent(name) + "&body=" + encodeURIComponent(clipboard);

            SaveForLater.showSaveOptions($(this).closest('.save-for-later'), mailto, clipboard);
        });

        $('.save-for-later .save-all-panels').click(function () {
            let clipboard = "";

            $('.panel-block').each(function (index, panelBlock) {
                panelBlock = $(panelBlock);
                clipboard += panelBlock.attr('data-panel-name') + "\n";
                clipboard += panelBlock.find('a[rel~=sponsored]')[0].href + "\n\n";
            });

            const mailto = "mailto:?subject=" + encodeURIComponent("Enquête sites") + "&body=" + encodeURIComponent(clipboard);

            SaveForLater.showSaveOptions($(this).closest('.save-for-later'), mailto, clipboard);
        });
    },

    showSaveOptions: function (container, mailto, clipboard) {

        const emailButton = $('<a href="' + mailto + '" class="button-extra-small button-gray">Email</a>');
        emailButton[0].onclick = function () {
            $(container).empty();
        };

        const copyButton = $('<a href="javascript:;" class="button-extra-small button-gray">Kopieren</a>');
        copyButton[0].onclick = function () {
            SaveForLater.copyToClipboard(clipboard);
            $(container).empty();
        };

        container.find('.buttons').empty().append(emailButton).append(copyButton);
    },

    copyToClipboard: function (textToCopy) {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);

        alert('De tekst is gekopieerd!');
    }
};

export default SaveForLater;
