const ToC = {

    init: function () {
        $('.toc .toc-title').click(function() {
            $(this).closest('.toc').find('> ul').toggle();
        });
    }

};

export default ToC;
