const Analytics = {
    init: function () {
    },

    virtualPageView: function (url, title) {
        if (!window.dataLayer) {
            return;
        }

        window.dataLayer.push({
            'event': 'VirtualPageview',
            'virtualPageURL': url,
            'virtualPageTitle': title
        });
    }

};

export default Analytics;
