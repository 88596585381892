const ErrorHandler = {

    init: function () {
    },

    defaultErrorHandler: function () {
        console.log(arguments);
        alert('Er is onverwachts een fout opgetreden. Probeert u het nogmaals. ' +
            'Neem contact met ons op als het probleem zich blijft voordoen.');
    }

};

export default ErrorHandler;
