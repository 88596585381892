const Modal = {

    overlay: $('<div id="overlay"></div>').hide(),

    init: function () {
        $('body').append(this.overlay);
    },

    showElementInModal: function (elementId) {
        this.overlay.show()
            .empty()
            .append($(elementId).show());

        $('#page').addClass('blur');
        $('body').addClass('no-scroll');
    },

    hideModal: function () {
        $('#overlay').hide();
        $('#page').removeClass('blur');
        $('body').removeClass('no-scroll');
    }

};

export default Modal;
