const Localization = {
    locale: '',

    init: function () {
        const matches = document.body.className.match(/locale-(\S+)/);

        if (matches) {
            this.locale = matches[1];
        }
    }

};

export default Localization;
