const Menu = {
    menu: $('#menu'),
    toggle: $('.menu-toggle'),

    init: function () {
        this.bindUIActions();
    },

    bindUIActions: function () {
        this.toggle.on('click', function () {
            Menu.toggleMenu();
        });

        this.menu.find('a').on('click', function () {
            Menu.closeMenu();
        });
    },

    toggleMenu: function () {
        if (this.menu.hasClass('open')) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    },

    openMenu: function () {
        this.menu.animate({left: 0}, 'normal');
        this.menu.addClass('open');
    },

    closeMenu: function () {
        this.menu.animate({left: -1 * this.menu.outerWidth()}, 'normal');
        this.menu.removeClass('open');
    }
};

export default Menu;
