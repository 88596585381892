const FAQ = {

    init: function () {
        this.bindUIActions();
    },

    bindUIActions: function () {
        $('.question h3').on('click', function () {
            $(this).next('.answer').slideToggle();
        });
    }

};

export default FAQ;
