import ErrorHandler from "./error-handler.js";
import Session from "./session.js";
import PanelSelect from "./panel-select.js";

const EmailRegistration = {

    container: $('#email-registration'),
    emailInput: $('#email-registration input[name=email]'),
    accept: $('#email-registration input[type=checkbox]'),
    acceptMessage: $('#email-registration').attr('data-accept-message'),
    errorMessage: $('#email-registration .error-message'),
    button: $('#email-registration .button-large'),
    ladda: null,
    URL: null,
    joinButtons: $('.join'),

    init: function () {
        this.URL = this.container.attr('data-register-url');

        if (this.button.size()) {
            this.ladda = Ladda.create(this.button.get(0));
        }

        this.bindUIActions();
    },

    bindUIActions: function () {
        this.button.on('click', function (e) {
            if (EmailRegistration.checkAcceptedTermsAndConditions()) {
                EmailRegistration.startLoadingAnimation();
                EmailRegistration.registerEmail();
            }

            e.preventDefault();
        });

        this.joinButtons.on('click', function () {
            $('html,body').animate({scrollTop: 20}, 'slow', null, function () {
                EmailRegistration.emailInput.focus();
            });
            return false;
        });
    },

    checkAcceptedTermsAndConditions: function () {
        if (!this.accept.prop('checked')) {
            alert(this.acceptMessage);
            return false;
        }

        return true;
    },

    registerEmail: function () {
        this.clearErrors();

        $.post(this.URL, {email: this.emailInput.val(), source: Session.getTrafficSource()}, function (data) {
            EmailRegistration.registrationSucceeded(data);
        }).fail(function (xhr) {
            EmailRegistration.stopLoadingAnimation();

            if (xhr.status === 422) {
                console.log(xhr, xhr.responseJSON);
                EmailRegistration.validationFailed(xhr.responseJSON.errors.email[0]);
            } else {
                ErrorHandler.defaultErrorHandler.apply(this, arguments);
            }
        });
    },

    registrationSucceeded: function (data) {
        Session.setUserId(data.user_id);
        PanelSelect.showPanelSelection(data.panels);
    },

    validationFailed: function (message) {
        this.emailInput.addClass('error');
        this.errorMessage.text(message).show();
    },

    clearErrors: function () {
        this.emailInput.removeClass('error');
        this.errorMessage.text('');
    },

    startLoadingAnimation: function () {
        this.ladda.start();
    },

    stopLoadingAnimation: function () {
        this.ladda.stop();
    }

};

export default EmailRegistration;
