import AdditionalInfo from "./additional-info.js";
import Analytics from "./analytics.js";
import Modal from "./modal.js";

const PanelSelect = {

    container: $('#panels'),
    proceed: $('#panels button'),
    URL: '/panels',
    panelTemplate: $('#panel-template'),
    panels: [],
    acceptedPanels: [],
    declinedPanels: [],

    init: function () {
        this.bindUIActions();
    },

    bindUIActions: function () {
        this.proceed.on('click', function () {
            PanelSelect.saveSelectedPanels();
            PanelSelect.hidePanelSelection();
            AdditionalInfo.showAdditionalInfo();
        })
    },

    showPanelSelection: function (panels) {
        this.panels = panels;

        Analytics.virtualPageView('/v2.0/1.choosepanels', 'v2.0 Step 1 – Choose Panels');
        Modal.showElementInModal("#panel-selection");
        this.showPanels();
    },

    hidePanelSelection: function () {
        Modal.hideModal();
    },

    showPanels: function () {
        for (let i = 0; i < this.panels.length; i++) {
            const panel = this.panelTemplate.clone();
            const panelId = 'panel-' + this.panels[i].id;

            panel.removeAttr('id');
            panel.find('input').attr('id', panelId);
            panel.find('label')
                .attr('for', panelId)
                .text(this.panels[i].name);

            if (!this.panels[i].rewards) {
                this.panels[i].rewards = [];
            }

            for (let j = 0; j < this.panels[i].rewards.length; j++) {
                panel.find('.rewards-' + this.panels[i].rewards[j]).show();
            }

            panel.show().insertBefore(this.proceed);
        }
    },

    saveSelectedPanels: function () {
        this.container.find('input[type=checkbox]').each(function (index, element) {
            element = $(element);

            if (!element.attr('id')) {
                return;
            }

            const panelId = element.attr('id').replace('panel-', '');
            if (element.is(':checked')) {
                PanelSelect.acceptedPanels.push(panelId);
            } else {
                PanelSelect.declinedPanels.push(panelId);
            }
        });
    }

};

export default PanelSelect;
