import Analytics from "./analytics.js";
import Modal from "./modal.js";
import PanelSelect from "./panel-select.js";
import Session from "./session.js";
import ErrorHandler from "./error-handler.js";

const AdditionalInfo = {

    container: $('#additional-info'),
    form: $('#additional-info form'),
    acceptedPanels: $('input[name=accepted_panels]'),
    declinedPanels: $('input[name=declined_panels]'),
    finish: $('#finish'),
    ladda: null,
    URL: null,
    thanksPageURL: '',

    init: function() {
        this.URL = this.container.attr('data-finish-registration-url');

        this.bindUIActions();
        this.initDateOfBirthFieldsChangeListener();

        this.thanksPageURL = this.container.attr('data-thanks-url');

        if (this.finish.size()) {
            this.ladda = Ladda.create(this.finish.get(0));
        }
    },

    bindUIActions: function() {
        this.finish.on('click', function(e) {
            e.preventDefault();
            AdditionalInfo.finishRegistration();
        });
    },

    initDateOfBirthFieldsChangeListener: function() {
        this.container.on('change', '[name^=date_of_birth_]', function() {
            const parts = [
                $('[name=date_of_birth_day]').val(),
                $('[name=date_of_birth_month]').val(),
                $('[name=date_of_birth_year]').val()
            ];

            $('[name=date_of_birth]').val(parts.join("-"));
        });
    },

    showAdditionalInfo: function() {
        Analytics.virtualPageView('/v2.0/2.moredetails', 'v2.0 Step 2 – More Details');
        Modal.showElementInModal("#additional-info");
    },

    finishRegistration: function() {
        this.startLoadingAnimation();

        this.acceptedPanels.val(PanelSelect.acceptedPanels.join(','));
        this.declinedPanels.val(PanelSelect.declinedPanels.join(','));
        const data = "id=" + Session.getUserId() + "&" + this.form.serialize();

        $.post(this.getPostURL(), data, function() {
            AdditionalInfo.registrationFinishedSuccessful();
        }).fail(function(xhr) {
            AdditionalInfo.stopLoadingAnimation();

            if (xhr.status === 422) {
                AdditionalInfo.validationFailed(xhr.responseJSON);
            } else {
                ErrorHandler.defaultErrorHandler.apply(this, arguments);
            }
        })
    },

    registrationFinishedSuccessful: function() {
        Modal.hideModal();
        this.redirectToThanksPage();
    },

    validationFailed: function(errors) {
        this.clearErrors();
        this.setErrors(errors);
    },

    clearErrors: function() {
        this.container.find('.error').removeClass('error');
    },

    setErrors: function(errors) {
        for (const property in errors) {
            if (property === 'date_of_birth') {
                this.container.find("[name^=date_of_birth_]").addClass('error');
            } else if (errors.hasOwnProperty(property)) {
                this.container.find("[name=" + property + "]").addClass('error');
            }
        }
    },

    startLoadingAnimation: function() {
        this.ladda.start();
    },

    stopLoadingAnimation: function() {
        this.ladda.stop();
    },

    getPostURL: function() {
        return this.URL + "?cid=" + Session.getVoluumCid() + "&source=" + Session.getTrafficSource();
    },

    redirectToThanksPage: function() {
        let url = this.thanksPageURL + "?user_id=" + Session.getUserId();
        url += "&cid=" + Session.getVoluumCid();
        url += "&source=" + Session.getTrafficSource();

        window.location.replace(url);
    }

};

export default AdditionalInfo;
