import Localization from "./localization.js";

const ClickTracking = {

    init: function () {
        $('.panel-block a[rel~=sponsored]').click(function () {
            const anchorElement = $(this);
            const panelBlockElement = $(this).closest('.panel-block');
            const cookie = JSON.parse(ClickTracking.getCookie('ts'));
            const data = {
                cid: cookie.cid,
                panel_name: panelBlockElement.attr('data-panel-name'),
                link_url: anchorElement.attr('href'),
                link_source: panelBlockElement.attr('data-link-source'),
                traffic_source: cookie.source,
                device: cookie.device,
            };

            if (cookie.source == 'google_ads') {
                data.gclid = cookie.gclid ?? null;
                data.wbraid = cookie.wbraid ?? null;
                data.gbraid = cookie.gbraid ?? null;
                data.campaign_id = cookie.campaign_id ?? null;
                data.ad_group_id = cookie.ad_group_id ?? null;
                data.keyword = cookie.keyword ?? null;
                data.match_type = cookie.match_type ?? null;
                data.device = cookie.device ?? null;
            }

            $.ajax('/' + Localization.locale + '/click', {
                method: 'POST',
                data: data,
            });

            return true;
        });
    },

    getCookie: function (name) {
        let cookies = decodeURIComponent(document.cookie).split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];

            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }

            if (cookie.indexOf(name + "=") == 0) {
                return cookie.substring(name.length + 1, cookie.length);
            }
        }

        return "";
    }
};

export default ClickTracking;
