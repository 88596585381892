(function($) {
    var ProgressBar = function(element, options) {
        var settings = $.extend({
            width: 200,
            outerRingWidth: 10,
            outerRingColor: '#d1d1d1',
            innerRingWidth: 20,
            innerRingColor: '#e6e6e6',
            progressRingColor: '#76e1e5',
            fillColor: '#ffffff'
        }, options || {});

        var element = $(element);
        var progress = element.attr('data-progress') || 0;
        var radius = settings.width / 2;

        this.init = function() {
            var width = settings.width + 'px';

            element.css('width', width).css('height', width);

            this.drawInactive();
            this.drawProgress();
        };

        this.drawInactive = function() {
            var context = element.find('.progress-inactive').get(0).getContext('2d');

            context.canvas.width = settings.width;
            context.canvas.height = settings.width;
            context.lineCap = 'square';

            //outer ring
            context.beginPath();
            context.lineWidth = settings.outerRingWidth;
            context.strokeStyle = settings.outerRingColor;
            context.arc(radius, radius, radius - (settings.outerRingWidth / 2), 0, 2 * Math.PI);
            context.stroke();

            //progress bar
            context.beginPath();
            context.lineWidth = 0;
            context.fillStyle = settings.innerRingColor;
            context.arc(radius, radius, radius - settings.outerRingWidth + 1, 0, 2 * Math.PI);
            context.fill();

            //progressbar caption
            context.beginPath();
            context.lineWidth = 0;
            context.fillStyle = settings.fillColor;
            context.arc(radius, radius, radius - settings.outerRingWidth - settings.innerRingWidth, 0, 2 * Math.PI);
            context.fill();
        };

        this.drawProgress = function(){
            var percentage = progress === 0 ? 0 : progress / 100;
            var context = element.find('.progress-active').get(0).getContext('2d');
            var endingAngle = ((2 * percentage) * Math.PI) - Math.PI / 2;
            var startingAngle = 0 - Math.PI / 2;

            context.canvas.width = radius * 2;
            context.canvas.height = radius * 2;
            context.lineCap = 'square';

            context.beginPath();
            context.lineWidth = settings.innerRingWidth;
            context.strokeStyle = settings.progressRingColor;
            var pradius = radius - settings.outerRingWidth - (0.5 * settings.innerRingWidth);
            context.arc(radius, radius, pradius, startingAngle, endingAngle);
            context.stroke();

            element.find('.progress')
                .css('font-size', settings.width * 0.2)
                .css('line-height', settings.width + 'px')
                .text(progress + '%');
        };

    };

    $.fn.progressBar = function(options) {
        return this.each(function() {
            var element = $(this);

            if (element.data('progressBar')) {
                return;
            }

            var progressBar = new ProgressBar(this, options);
            progressBar.init();

            element.data('progressBar', progressBar);
        });
    };
}(jQuery));