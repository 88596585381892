const CSRFToken = {

    init: function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name=_token]').attr('content')
            }
        })
    }

};

export default CSRFToken;
