const Session = {

    userId: null,
    voluumCid: null,
    trafficSource: null,

    init: function () {
        this.voluumCid = this.getQueryStringParameter("cid");
        this.trafficSource = this.getQueryStringParameter("source");
    },

    getUserId: function () {
        return this.userId;
    },

    setUserId: function (userId) {
        this.userId = userId;
    },

    getVoluumCid: function () {
        return this.voluumCid;
    },

    getTrafficSource: function () {
        return this.trafficSource || "";
    },

    getQueryStringParameter: function (parameterName) {
        parameterName = parameterName.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\?&]" + parameterName + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

};

export default Session;
