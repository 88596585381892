import Menu from "./modules/menu.js";
import CSRFToken from "./modules/csrf-token.js";
import ErrorHandler from "./modules/error-handler.js";
import Analytics from "./modules/analytics.js";
import Session from "./modules/session.js";
import Modal from "./modules/modal.js";
import EmailRegistration from "./modules/email-registration.js";
import PanelSelect from "./modules/panel-select.js";
import AdditionalInfo from "./modules/additional-info.js";
import FAQ from "./modules/faq.js";
import ToC from "./modules/toc.js";
import ClickTracking from "./modules/click-tracking.js";
import Localization from "./modules/localization.js";
import SaveForLater from "./modules/save-for-later.js";

$(function () {
    Menu.init();
    CSRFToken.init();
    ErrorHandler.init();
    Analytics.init();
    Session.init();
    Modal.init();
    EmailRegistration.init();
    PanelSelect.init();
    AdditionalInfo.init();
    FAQ.init();
    ToC.init();
    Localization.init();
    ClickTracking.init();
    SaveForLater.init();

    $('[placeholder]').placeholder();
    $('.desktop .progress-bar').progressBar({width: 85, outerRingWidth: 5, innerRingWidth: 10});
    $('.tablet .progress-bar').progressBar({width: 60, outerRingWidth: 4, innerRingWidth: 8});
    $('.back-to-top').click(function () {
        $("html, body").animate({scrollTop: 0}, 500);
        history.pushState(null, null, ' ')
    });
});
